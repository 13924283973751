.jumbotron,
.stripe {

	h1,
	h3 {
		font-weight: $headings-font-weight;
		font-family: $font-family-goth;
		// line-height: 1.5;
		// margin-bottom: 15px;
		// width: 75%;
	}

	h1 {
		// font-size: 24px;

		br {
			display: block;
		}
	}

	h3 {
		// font-size: 18px;
	}
}

.jumbotron {
	padding: 30px 0 30px 0;

	p {
		font-weight: 300;
		font-family: $font-family-goth;
	}

	.jumbotron-logo {

		img {
			margin: 0 auto 30px auto;
		}
	}

	.d-desktop {
		display: none;
	}

	.d-mobile {
		display: block;
	}

	.btn {
		width: 100%;
		max-width: 400px;
	}
}

.btn {
	font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
}

.heading-box {
	h1 {
		br {
			display: block;
		}
	}
}



@include media-breakpoint-up (sm) {

	.jumbotron,
	.stripe {

		h1 {
			line-height: 1.5;
		}

		.btn {
			max-width: 300px;
		}
	}

}

@include media-breakpoint-up(md) {

	.jumbotron {
		h1 {

			br {
				display: none;
			}
		}
	}
}

@include media-breakpoint-up(lg) {

	.heading-box {
		height: auto;
	}

	.jumbotron {
		.d-desktop {
			display: block;
		}

		.d-mobile {
			display: none;
		}

	}
}

@include media-breakpoint-up(md) {
	.jumbotron {
		padding: 70px 0 70px 0;
	}
}