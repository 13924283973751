// form
body:not(.page_1) {

    .container-form,
    .container-form-confirm {
        position: relative;
        padding: 30px;
        min-width: 300px;
    
        h2 {
            font-weight: 500;
        }
    }
    
    label {
        font-weight: 300;
    }
    
    input,
    select,
    textarea {
        font-family: $font-family-base;
        font-style: italic;
    }
    
    textarea.form-control {
        resize: vertical;
    }
    
    .form-control.error {
        border: 1px solid #ff0000;
    }
    
    .form-group.is-invalid {
        
        .radio label {
            color: #dc3545;
        }
    }
    
    .radio {
        strong {
            font-weight: bold;
        }
    }
    
    label,
    input,
    p {
        margin: 10px 0;
    }
    
    #frame-cancle-btn {
        &:hover {
            cursor: pointer;
        }
    }
    
    
    @include media-breakpoint-up(sm) {
    
        .container-form {
            padding: 0;
        }
    
    }
    
    @include media-breakpoint-down(md) {
    
        .container-form {
            width: auto;
        }
    
    }
    
    @include media-breakpoint-up(md) {
    
        .container-form-confirm {
            margin-top: 100px;
        }
    
    }
}
