// passage



.passage {

  img {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

.page_1 {

  // anchor helper
  .passage-anchor {
    position: relative;
    top: -100px;
  }

  .passage {
    margin-top: 50px;
    margin-bottom: 100px;
  }

  h2 {
    font-family: $font-family-base;
    font-size: 29px;
    font-weight: 300;
  }
}


// ********************