.form-voucher {
	max-width: 500px;
}

.jumbotron {
	.heading-box-logo {
		width: 200px;
	}
}

.jumbotron,
.stripe {

	h1, h3 {
		font-size: 24px;
	}
}

.jumbotron-logo-label {
	margin: -6px 0 20px 13px;
	font-size: 12px;
	font-family: $font-family-base;
	text-align: left;
}

@include media-breakpoint-up(sm) {
	.jumbotron,
	.stripe {

		h1,
		h3 {
			font-size: 22px;
			line-height: 1.4;
		}
	}
} 

@include media-breakpoint-up(lg) {
	.jumbotron {

		.jumbotron-logo-label {
			margin: -31px 0 0 19px;
			font-size: 12px;
			font-family: $font-family-base;
			text-align: center;
		}
	}
}