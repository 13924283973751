// custom.less

// ***************



.footer .footer-celogo {
	margin-top: 8px;
}

// ***************

.jumbotron {
	padding: 0;
}

.jumbotron .container {
	position: relative;
	height: 400px;
}

.jumbotron-image {

	right: 0px;
	left: auto;

	position: absolute;
	top: 0px;
	height: 400px;
	z-index: 8;

	img {
		height: 100%;
	}
}

.jumbotron-cover {
	display: block;
	position: absolute;
	top: 185px;
	left: 0px;
	width: 100%;
	height: 215px;
	z-index: 9;
	background: #fff;
	opacity: 0.6;
}

.jumbotron-content {
	top: 190px;
	right: auto;
	left: 0;
	width: 80%;
	height: 210px;
	padding: 0 10px;
	position: absolute;
	z-index: 10;

	h1 {
		margin-bottom: 100px;
		font-size: 19.8px;
		font-family: $font-family-base;
		font-weight: 400;
	}

	a {
		color: #337ab7;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 16px;
		opacity: 0.8;
		border-bottom: 1px solid #337ab7;

		svg {
			width: 20px;
			height: 20px;
			fill: #337ab7;
			margin-top: 3px;
		}
	}

	a:hover,
	a:active {
		text-decoration: none;
		opacity: 1;
	}

	h4 {
		position: absolute;
		left: 0;
		bottom: 40px;

		a {
			display: block;
			width: 190px;
			background-color: rgba(146, 198, 217, 0.8);
			border: 1px solid rgba(146, 198, 217, 0.1);
			// border-radius: @border-radius-base;
			padding: 6px 10px;

			font-size: 0.7em;
			color: #fff;
			opacity: 1;

			&:hover,
			&:visited,
			&:focus,
			&:active {
				background-color: rgba(146, 198, 217, 1);
				opacity: 1;
			}
		}
	}
}

.jumbotron-link {
	position: absolute;
	bottom: 10px;
}

@include media-breakpoint-up(sm) {
	.jumbotron-image {
		right: auto;
		left: 0px;
	}

	.jumbotron-cover {
		display: none;
	}

	.jumbotron-content {
		position: absolute;
		top: 0px;
		right: 0px;
		left: auto;
		width: 50%;
		height: 400px;
		padding: 48px 0;

	}

	.jumbotron-link {
		left: 10px;
	}

	.jumbotron {
		p {
			font-size: ($font-size-base * 1.1);
			font-weight: 400;
		}
	}
}

@media screen and (min-width: 480px) and (max-width: 768px) {
	.jumbotron-image {
		right: 0px;
		left: auto;
	}

	.jumbotron-content {
		right: auto;
		left: 0;
		width: 50%;
		margin: 0 30px;

	}

	.jumbotron {
		p {
			font-size: ($font-size-base * 1.1);
			font-weight: 400;
		}
	}
}

@media screen and (min-width: 768px) {
	.jumbotron {
		margin-bottom: 30px;

		p {
			font-size: ($font-size-base * 1.5);
		}
	}

	.jumbotron-content {
		width: 40%;

		h1 {
			font-size: 27px;
			font-weight: 200;
		}
	}
}

@media screen and (min-width: 1200px) {
	.jumbotron-content {
		width: 50%;
	}
}


// ***************

.spots-bg {
	background-color: #DFEFF5;
}

@media screen and (min-width: 768px) {
	.spots-bg {
		padding-top: 30px;
	}
}

.spots {

	.spot-content {
		position: relative;
		z-index: 11;
	}

	.spot-icon {
		z-index: 10
	}

	.spot-icon i {
		color: #C5E2ED;
	}
}