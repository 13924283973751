.jumbotron {

	.heading-box {
		display: flex;
		flex-direction: row;
	}

	.d-mobile {
		width: 60%;
		max-width: 240px;
		margin: 0 auto 30px auto;
	}
}

.jumbotron,
.stripe {
	h1 {
		font-size: 24px;
	}

	h3 {
		font-size: 18px;
	}

	h1, h3 {
		line-height: 1.5;
		margin-bottom: 15px;
		width: 75%;
	}

	.form-control {
		max-width: 400px;
	}
}

@include media-breakpoint-up(sm) {
	.jumbotron,
	.stripe {
		h1 {
			font-size: 27px;
		}

		h3 {
			font-size: 24px;
		}

		.form-control {
			max-width: 300px;
		}
	}
} 

@include media-breakpoint-up(md) {

	.jumbotron {
		h1 {
			font-size: 33px;

			br {
				display: none;
			}
		}

		h3 {
			font-size: 28px;
		}
	}
} 

@include media-breakpoint-up(lg) {
	.jumbotron,
	.stripe {

		h1 {
			width: 100%;
		}
		.heading-box {
			display: block;
		}
	}
}