#spots {
	position: relative;
	top: -50px;
}

// ********************

.spot {
	position: relative;
}

.spots {
	margin: 30px 0;

	div.row {
		padding-top: 30px;
		padding-bottom: 30px;
	}

}

@include media-breakpoint-up (sm) {
	.spots {
		margin: 50px 0;

	}
}
