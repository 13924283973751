// form

.page_2,
.page_3 {

    .jumbotron,
    .stripe {

        h1,
        h3 {
            color: $brand-css;
        }
    }

    .stripe {
        h2 {
            color: $brand-css;
        }

        .btn {
            color: $brand-css;
    		border-color: $brand-css;
        }
    }

    .container-form-confirm {
        h2 {
            color: $brand-css;
        }
    }

    .btn-custom-brand {
        color: #fff;
        background-color: $brand-css;
        border-color: $brand-css;
    }
}

.page_4 {

    .jumbotron,
    .stripe {

        h1,
        h3 {
            color: $brand-hms;
        }
    }

    .stripe {
        h2 {
            color: $brand-hms;
        }

        .btn {
            color: $brand-hms;
            border-color: $brand-hms;
        }
    }

    .container-form-confirm {
        h2 {
            color: $brand-hms;
        }
    }

    .btn-custom-brand {
        color: #fff;
        background-color: $brand-hms;
        border-color: $brand-hms;
    }

    .btn-link {
        color: $brand-hms;
    }

}