.stripe {
	// background-color: #F7F7F7;
	padding: 0 0 30px 0;

	.row {
		padding-left: 0;
	}


	.btn {
		display: block;
		width: 146px;
	}
}

@include media-breakpoint-up(sm) {

	.stripe {
		padding: 0 0 30px 0;

	}
}


@include media-breakpoint-up(md) {
	.borderline {
		margin: 0px 0 30px;
	}
	
	.borderline-inner {
		display: block;
		height: 1px;
		width: 376px;
		background-color: #333;
	}
}

@include media-breakpoint-up(lg) {

	.stripe {
		padding: 0 0 60px 0;

	}

	.borderline {
		margin: 0px 0 60px;
	}
}